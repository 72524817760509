import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import "./hero.scss";

function HeroSection() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
      once: true,
    });
  }, []);

  return (
    <div className="section-hero" id="hero">
      <div className="hero-wrap container">
        <div className="hero-content">
          <div className="hero-content-left" data-aos="fade-right" data-aos-delay="300" data-aos-duration="1500">
            <h1>LEADER ELD</h1>
            <h4>
              Manage your fleet effortlessly with everything in one place—driver safety, GPS tracking, and ELD
              compliance. Our platform offers the easiest-to-use ELD device and application.
            </h4>
          </div>
          <div className="hero-content-right" data-aos="fade-left" data-aos-delay="300" data-aos-duration="1500">
            <img src={require("src/assets/img/hero1.png")} alt="hero" className="hero-img-1" draggable={false} />
            <img src={require("src/assets/img/hero2.png")} alt="hero" className="hero-img-2" draggable={false} />
            <img src={require("src/assets/img/hero3.png")} alt="hero" className="hero-img-3" draggable={false} />
          </div>
        </div>
        <div className="hero-buttons" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1500">
          <button className="button-filled filled">
            <a href="#contact">Contact us</a>
          </button>
          <button className="button-outlined outlined">
            <a href="#contact">Get started</a>
          </button>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
